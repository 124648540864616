.previewbtn {
    background-color: #63E000;
    color: aliceblue;
    border-radius: 7px;
    border: none;
    box-shadow: 2px 2px #c0c0c0;
    
}

.previewbtn:hover {
    background-color: #54bb00;
}

.tester {
    display: flex;
    margin-left: auto;
    margin-right: auto;
}

.disabledbutton {
    background-color: #c0c0c0;
    color: rgb(0, 0, 0);
    border-radius: 7px;
    border: none;
    box-shadow: 2px 2px #c0c0c0;
}

.disabledbutton:hover {
    background-color: #919191;
    color: #FFFFFF;
}

.understyle {
    margin-left: 1px; 
    background-color:#18A0FB; 
    border-radius: 0px 10px 10px 0px; 
    padding-bottom: 2px;
}

.closebtns {
    background-color: #dadada;
    color: #000000;
    border-radius: 5px;
    border: none;
    box-shadow: 2px 2px #ffffff;
    margin-right: -5px;
}

.closebtns:hover {
    background-color: #ffffff;
}

.openbtns {
    background-color: #ffffff;
    color: #000000;
    border-radius: 5px;
    border: none;
    border-top: 1px solid #000000;
    box-shadow: 2px 2px #e0e0e0;
    margin-right: -5px;
}

.defaultfont {
    font-size: 16px;
}

.unbolder {
    font-weight: 300;
}


.mkfamily {
    font-family: Arial, Helvetica, sans-serif;
    flex-direction: column; 
    min-height: 100vh; 
    display: flex;
}


.footerstyle {
    position: fixed;
    left: 0;
    bottom: 0;
}

.bordertester {
    margin-left: -10px;
}

.borderer {
    margin-left: 10px;
}

.cardheader {
    border-radius: 20px 20px 0px 0px;
}

.cardheader_mid {
    border-radius: 0px 0px 0px 0px;
}
.Irounded{
    border-radius: 20px;
}

.contained {
    margin-top: 80px;
    margin-left: 250px;
}

.m_contained {
    margin-left: 250px;
}

.cardbg {
    background-color: rgba(51, 128, 255, 0.15);
    border-radius: 17px;
}

.tablehead {
    z-index: 1;
    font-weight: bold; 
    background-color: #18A0FB; 
    color: rgb(255, 255, 255);
}

.tableoverflow{
    overflow-x: auto;
}

.tablesize-sm{
    max-height: 300px;
}

.tablesize-xsm{
    max-height: 210px;
}

.sticktop {
    position: sticky;
    top: -10px;
}

.borderdivide_left{
    border-left: 1px solid #b8b8b8;
}

.borderdivide_right{
    border-right: 1px solid #000000;
    box-shadow: 1px 0px 0px 0px rgb(85, 85, 85);
    
}

.borderdivide_bottom{
    border-bottom: 1px solid #b8b8b8;
    padding-bottom: 15px;
    align-self: center;
 	width: 90%;
	border-bottom: 1px solid rgb(126, 126, 126);
    box-shadow: 0px 1px 0px 0px rgb(126, 126, 126);
}

.borderdivide_top{
    border-top: 1px solid #b8b8b8;
}


.piechartbox {
    width: 325px;
    height: 325px;
}

@media only screen and (max-width: 600px){
    .piechartbox{
        width: 90%;
        height: 45%;
    }    
}

.areachartbox {
    width:100%;
    min-height: 300px;
}

.flexbox {
    display: flex;
    align-items: center;
    justify-content: center;
}

.hrstyle {
    border: none;
    height: 20px;
    align-self: center;
 	width: 90%;
	margin-top: 0;
	border-bottom: 2px solid rgb(83, 83, 83);
	box-shadow: 0px 1px 3px 1px rgb(0, 0, 0);
}

.borderless {
    border: none;
}

.titlelift {
    float: left;
    margin-top: 3px; 
    font-weight: 900; 
    color: #31669c;
    padding-bottom: 50px;
}

.numbers {
    color:#000000;
}

.modifiedtable {
    background-color: #FFFFFF;
    font-weight: 400;
    color: #000000;
}


.colorfix {
    color:#000000;
}

.sizefix {
    font-size: 25px;
}

.booster {
    font-weight: 700;
}

/* Used in the dashboard to position it like other pages */
.specialconfig {
    margin-left: -15px;
}



/* Modified Rashad Codes */
.nav1_mid {
    position: relative;
	margin: 0px auto 0;
	width:  auto 0;
	height: 60px;
	background: #18A0FB;
	border-radius: 0px 0px 0px 0px; 
    color: black;
}

.nav1_mid h6, h5 {
    color: #ffff
}

/* Rashad Profile Style START */
/* MERGE 1 - COMMENTS */


.maincolor {
	background-color: rgba(51, 128, 255, 0.15);
}


.understylee {
    margin-left: 50px;
    margin-right: 35px; 
    background-color: #18A0FB;
    border-radius: 3px 3px 3px 3px; 
    padding-top: 10px;
    height: 40px;      
}

.understylee:hover {
    background-color:rgba(182, 185, 192, 0.288); 
}


.hrstyle{
    border: 2px solid #309ae2
}

.piechartbox2 {
    width:90%;
    min-height: 300px;
}

/* MERGE 2 - COMMENTS */

.nav1{
	position: relative;
	margin: 0px auto 0;
	width:  auto 0;
	height: 60px;
	background: #18A0FB;
	border-radius: 15px 15px 0px 0px; 
    color: #ffffff;  
    margin-bottom: 10px;
}

.nav1 h6, h5 {
    color: #ffff;
    margin: 0;
    margin-top: 4px;
}

.nav3{
	position: relative;
	margin: 0px auto 0;
	width: 600px auto;
	height: 60px;
	background: #18A0FB;
	border-radius: 15px 15px 0px 0px;
    color: black;   
}
.nav3 h6{
    color: #ffffff;
}



/* MyDetails Component Draw */
.mydrow{
    position: relative;
    background:rgba(187, 187, 187, 0.15);
    margin-left: 12px;
    margin-bottom:5px;
    margin-top: 5px;   
}
/*  */
.userrow{
    position: relative;
    background:rgba(187, 187, 187, 0.15);
    margin-left: 12px;
    border-radius: 3px;
    margin-top: 5px;
    margin-bottom:5px;
}

.staticrow{
    position: relative;
    background: rgba(187, 187, 187, 0.15);
    border-radius: 3px;
}
.staticrow h6 {
    margin-left: 4px;
    
}
.staticrow p {
    margin-left: 4px;
    
}

.upper-container img {
    height: 80px;
    width: 80px;
    margin-bottom: 10px;
    margin-top: -40px;
       
}


.image-container{
    text-align: center;
}



.lower-container h6 {
    text-align: center;
    
}

.lower-container p {
    text-align: center;
    
}

.cardpic{
    border-radius: 0px;
}

.card-backgpic{
    height: 60px;
    background-color: #18A0FB;
    border-radius: 15px 15px 0px 0px;
}




/* Rashad Profile Style END */

.errorFormat {
    color: red;
    font-weight: 500;
    font-size: small;
    text-align: center;
}

.colmodifier {
    margin-top: 0px;
    margin-bottom: 5px;
}

.excessmodifier {
    margin-top: -5px;
    margin-bottom: 10px;
}

.tinybits1 {
    margin-top: 15px;
    margin-bottom: -5px;
}

.psuedoInput{
    border: none;
    color: #000000;
    font-weight: 500;
}

.errorformat_inline {
    display: inline-flex;
    align-items: center;

}





















.aligned {
    margin-bottom: auto;
}





.mx-left-1{
    margin-left:-1px;
}
.mx-left-2{
    margin-left:-2px;
}
.mx-left-3{
    margin-left:-3px;
}
.mx-left-4{
    margin-left:-4px;
}
.mx-left-5{
    margin-left:-5px;
}
.mx-left-6{
    margin-left:-6px;
}
.mx-left-7{
    margin-left:-7px;
}
.mx-left-8{
    margin-left:-8px;
}
.mx-left-9{
    margin-left:-9px;
}
.mx-left-10{
    margin-left:-10px;
}


.mx-right-1 {
    margin-right: -1px;
}
.mx-right-2 {
    margin-right: -2px;
}
.mx-right-3 {
    margin-right: -3px;
}
.mx-right-4 {
    margin-right: -4px;
}
.mx-right-5 {
    margin-right: -5px;
}
.mx-right-6 {
    margin-right: -6px;
}
.mx-right-7 {
    margin-right: -7px;
}
.mx-right-8 {
    margin-right: -8px;
}
.mx-right-9 {
    margin-right: -9px;
}
.mx-right-10 {
    margin-right: -10px;
}


.mb--1{
    margin-bottom: -1vh;
}

.mb--2{
    margin-bottom: -2vh;
}

.mb--3{
    margin-bottom: -3vh;
}

.mb--4{
    margin-bottom: -4vh;
}

.mb--5{
    margin-bottom: -5vh;
}

.mb--6{
    margin-bottom: -6vh;
}

.mb--7{
    margin-bottom: -7vh;
}

.mb--8{
    margin-bottom: -8vh;
}

.mb--9{
    margin-bottom: -9vh;
}

.mb--10{
    margin-bottom: -10vh;
}

.mb-11{
    margin-bottom: -11vh;
}

.mb--12{
    margin-bottom: -12vh;
}


/* @media (max-width: 1536px)  {
        .aligned{
            margin-bottom: -150px;
        }
    }

    @media (max-width: 1900px)  {
        .aligned{
            margin-bottom: -100px;
        }
    } */






/* Chartbox media */

